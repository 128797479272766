<template>
    <ScrollableContainer>
        <div class="create-event bg-light text-primary">
            <div class="create-event__content">
                <header class="create-event__header">
                    <img
                        v-if="step > 0"
                        src="@/assets/icons/left_arrow_icon.svg"
                        class="icon-md"
                        @click="previous"
                    />
                    <img
                        src="@/assets/logo_primary.svg"
                        :style="{ width: '4rem', gridColumnStart: 2 }"
                    />
                    <button
                        class="button button-close"
                        @click="$router.replace('/profile/me')"
                    ></button>
                </header>

                <keep-alive>
                    <EventForm v-if="step === 0" @step="next" @input="event = $event" />
                </keep-alive>
                <!-- STEP 1 -->
                <p v-if="step === 1">Choose artists</p>
                <!-- <CollapsiblePanelStateless
                    v-if="step === 1"
                    :is-collapsed="isFilterVisible"
                    :style="{ position: 'relative' }"
                >
                    <template #header> -->
                <FilterBar
                    v-if="step === 1"
                    :text="
                        `${findEventArtist.length} artist${findEventArtist.length === 1 ? '' : 's'}`
                    "
                    :style="{ margin: '1rem 1rem 0' }"
                />
                <!-- </template>
                    <template #content>
                        <div class="filter-panel" :style="{ transformOrigin: 'top' }">
                            <Select placeholder="Category" small>
                                <template #elements>
                                    <option
                                        v-for="ac in artistCategories"
                                        :key="ac.id"
                                        :value="ac"
                                        >{{ ac.name }}</option
                                    >
                                </template>
                            </Select>
                        </div>
                    </template>
                </CollapsiblePanelStateless> -->
                <keep-alive>
                    <ProfileSelect
                        v-if="step === 1"
                        :value="selectedArtists"
                        :profile-list="findEventArtist"
                        :loading="$apollo.queries.findEventArtist.loading"
                        @update="selectedArtists = $event"
                        @step="next"
                    />
                </keep-alive>
                <!-- STEP 2 -->
                <p v-if="step === 2">Choose venues</p>
                <FilterBar
                    v-if="step === 2"
                    :text="
                        `${findEventVenue.length} venue${findEventVenue.length === 1 ? '' : 's'}`
                    "
                    :style="{ margin: '1rem 1rem 0' }"
                />
                <keep-alive>
                    <ProfileSelect
                        v-if="step === 2"
                        :value="selectedVenues"
                        :profile-list="findEventVenue"
                        :loading="$apollo.queries.findEventVenue.loading"
                        @update="selectedVenues = $event"
                        @step="next"
                    />
                </keep-alive>
                <keep-alive>
                    <AddMessage
                        v-if="step === 3"
                        :artists="selectedArtists"
                        :venues="selectedVenues"
                        :is-venue-needed="event.isVenueNeeded"
                        :is-artist-needed="event.isArtistNeeded"
                        :artist-min-price="event.artistMinPrice"
                        :artist-max-price="event.artistMaxPrice"
                        :venue-min-price="event.venueMinPrice"
                        :venue-max-price="event.venueMaxPrice"
                        :is-loading="isMutationLoading"
                        :loading-message="loadingMessage"
                        @previous="previous"
                        @input="initialMessages = $event"
                        @complete="create"
                    />
                </keep-alive>
            </div>
        </div>
    </ScrollableContainer>
</template>

<script>
import EventForm from './EventForm'
import ProfileSelect from '@/components/eventrequest/ProfileSelect'
import FilterBar from '@/components/eventrequest/FilterBar'
import AddMessage from './AddMessage'
// import CollapsiblePanelStateless from '@/components/CollapsiblePanelStateless'
// import Select from '@/components/Select'
import { UPLOAD_IMAGE } from '@/graphql/mutations/user.js'
import { CREATE_USER_EVENT } from '@/graphql/mutations/event.js'
import { CREATE_USER_EVENTREQUEST } from '@/graphql/mutations/eventRequest.js'
// import { ARTIST_CATEGORIES } from '@/graphql/queries/categories.js'
import { MY_EVENTS, UPCOMING_EVENTS } from '@/graphql/queries/event'
import { FIND_EVENT_ARTIST, FIND_EVENT_VENUE } from '@/graphql/queries/event.js'

import logger from '@/utils/logger.js'
export default {
    name: 'CreateEvent',
    components: {
        EventForm,
        ProfileSelect,
        AddMessage,
        FilterBar
        // Select,
        // CollapsiblePanelStateless
    },
    inject: ['switchToFanProfile'],
    apollo: {
        // artistCategories: {
        //     query: ARTIST_CATEGORIES
        // },
        findEventArtist: {
            query: FIND_EVENT_ARTIST,
            skip() {
                return !this.event?.isArtistNeeded
            },
            variables() {
                return {
                    where: {
                        location: {
                            lat: this.event.location.lat,
                            long: this.event.location.long
                        }
                    }
                }
            }
        },
        findEventVenue: {
            query: FIND_EVENT_VENUE,
            skip() {
                return !this.event?.isVenueNeeded
            },
            variables() {
                return {
                    where: {
                        location: {
                            lat: this.event.location.lat,
                            long: this.event.location.long
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            // artistCategories: [],
            // isFilterVisible: false,
            findEventArtist: [],
            findEventVenue: [],
            selectedArtists: [],
            selectedVenues: [],
            loading: 0,
            loadingMessage: '',
            success: false,
            step: 0,
            event: null,
            initialMessages: null,
            isMutationLoading: false
        }
    },
    computed: {
        eventDto() {
            return {
                input: {
                    data: {
                        name: this.event.name,
                        description: this.event.description,
                        // occasion: this.event.occasion,
                        eventStart: new Date(`${this.event.startDate} ${this.event.startTime}`),
                        eventEnd: new Date(`${this.event.endDate} ${this.event.endTime}`),
                        location: {
                            lat: this.event.location.lat,
                            long: this.event.location.long
                        }
                    }
                }
            }
        }
    },
    watch: {
        step() {
            // Scroll to top of site each time step changes
            document.querySelector('.scrollable').scrollTop = 0
        }
    },
    methods: {
        next() {
            let v = Math.min(this.step + 1, 4)
            if (v === 1 && !this.event.isArtistNeeded) {
                v = Math.min(v + 1, 4)
            }
            if (v === 2 && !this.event.isVenueNeeded) {
                v = Math.min(v + 1, 4)
            }
            this.step = v
        },
        async onEventCreated() {
            await this.switchToFanProfile()
            this.$router.replace('/events/requests/outgoing')
        },
        previous() {
            let v = Math.max(this.step - 1, 0)
            if (v === 2 && !this.event.isVenueNeeded) {
                v = 1
            }
            if (v === 1 && !this.event.isArtistNeeded) {
                v = 0
            }
            this.step = v
        },
        async createEventRequest(eventId) {
            this.loadingMessage = 'Sending requests'
            const payload = {}
            if (this.selectedArtists) {
                payload.artistData = {
                    message: this.initialMessages.artistMessage,
                    artists: this.selectedArtists.map((a) => a.id),
                    price: this.initialMessages.pricePerArtist
                }
            }
            if (this.selectedVenues) {
                payload.venueData = {
                    message: this.initialMessages.venueMessage,
                    venues: this.selectedVenues.map((a) => a.id),
                    price: this.initialMessages.pricePerVenue
                }
            }

            try {
                const eventRequestResult = await this.$apollo.mutate({
                    mutation: CREATE_USER_EVENTREQUEST,
                    variables: {
                        input: {
                            data: {
                                event: eventId,
                                ...payload
                            }
                        }
                    },
                    refetchQueries: [
                        { query: MY_EVENTS },
                        {
                            query: UPCOMING_EVENTS,
                            variables: {
                                variables: {
                                    where: {
                                        eventStart_gte: new Date(),
                                        status: 'COMPLETE'
                                    },
                                    limit: 10,
                                    sort: 'eventStart:asc'
                                }
                            }
                        }
                    ]
                })

                const {
                    eventrequests,
                    type,
                    errors
                } = eventRequestResult.data.createUserEventRequest

                if (type === 'ResponseError') {
                    errors.forEach((err) => {
                        logger.error(err)
                    })
                } else {
                    logger.log(eventrequests)
                    this.loadingMessage = 'Success'
                    this.onEventCreated()
                }
            } catch (error) {
                logger.error('Error when creating event requests:')
                logger.error(error)
            }
        },
        async createUserEvent(data) {
            try {
                const result = await this.$apollo.mutate({
                    mutation: CREATE_USER_EVENT,
                    variables: data
                })
                return result
            } catch (error) {
                logger.error('Error when creating event')
                logger.error({ error })
            }
        },
        async create() {
            logger.log('Creating event')
            this.isMutationLoading = true
            let imageId = null

            if (this.event.coverImageFile) {
                this.loadingMessage = 'Uploading image'
                try {
                    const response = await this.$apollo.mutate({
                        mutation: UPLOAD_IMAGE,
                        variables: {
                            file: this.event.coverImageFile
                        }
                    })
                    imageId = response.data.upload.id
                } catch (error) {
                    logger.log(error)
                }
            }

            const payload = {
                input: {
                    data: {
                        name: this.event.name,
                        description: this.event.description,
                        eventStart: new Date(`${this.event.startDate}T${this.event.startTime}`),
                        eventEnd: new Date(`${this.event.endDate}T${this.event.endTime}`),
                        address: { place: this.event.location.name },
                        location: {
                            lat: this.event.location.lat,
                            long: this.event.location.long
                        },
                        image: imageId
                    }
                }
            }

            if (imageId) {
                payload.input.data.image = imageId
            }

            this.loadingMessage = 'Creating Event'
            try {
                const {
                    data: {
                        createUserEvent: { event, errors, type }
                    }
                } = await this.createUserEvent(payload)
                if (type === 'ResponseError') {
                    errors.forEach(logger.log)
                } else {
                    logger.log('Event created')
                    if (!this.initialMessages.isNoneRequested) {
                        this.createEventRequest(event.id)
                    } else {
                        this.onEventCreated()
                    }
                }
            } catch (error) {
                logger.error('Error when creating event:', { error })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/sass/variables.scss';

.create-event {
    min-height: 100vh;
    text-align: center;
    padding-bottom: 110px;
    &__header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        :last-child {
            justify-self: end;
        }
        padding: 3rem 1rem 1rem;
    }
}
@media only screen and (min-width: 768px) {
    .create-event {
        &__content {
            max-width: 768px;
            margin: auto;
        }
    }
}
</style>
