<template>
    <ValidationProvider
        v-slot="{ errors }"
        mode="eager"
        :rules="{ timeregex: /^([01]\d|2[0-3]):?([0-5]\d)$/, required }"
        class="input-container"
    >
        <input
            v-bind="$attrs"
            type="time"
            placeholder="hh:mm"
            :name="name"
            :min="minString"
            :max="maxString"
            @input="$emit('input', $event.target.value)"
            @blur="$emit('blur', $event)"
        />
        <small v-if="errors" class="text--red">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules'
import { format } from 'date-fns'
extend('required', {
    ...required,
    message: '{_field_} is required'
})
extend('timeregex', {
    ...regex,
    message: 'Follow the standard time format hh:mm'
})
export default {
    name: 'TimeInput',
    components: {
        ValidationProvider
    },
    props: {
        name: {
            type: String,
            default: 'Time'
        },
        required: {
            type: Boolean,
            default: false
        },
        min: {
            type: Date,
            default: undefined
        },
        max: {
            type: Date,
            default: undefined
        }
    },
    emits: ['blur', 'input'],
    computed: {
        minString() {
            return this.min && this.formatTime(this.min)
        },
        maxString() {
            return this.max && this.formatTime(this.max)
        }
    },
    methods: {
        formatTime(date) {
            return format(date, 'HH:mm')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.input-container {
    display: grid;
    justify-items: center;
    * {
        min-width: 90%;
    }
}
::-webkit-datetime-edit {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-text {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-month-field {
    padding: 0;
}
::-webkit-datetime-edit-day-field {
    padding: 0;
}
::-webkit-datetime-edit-year-field {
    padding: 0;
}
::-webkit-inner-spin-button {
    padding: 0;
    display: block;
}

::-webkit-calendar-picker-indicator {
    padding: 0;
    margin: 0;
    width: 14px;
    height: 14px;
    &:focus {
        outline: 0;
    }
}

input[type='time'] {
    font-family: inherit;
    background-color: white;
    line-height: 1rem;
    margin: 0;
    border-radius: 0;

    padding: 1rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
    border: 0;
    outline: 0;
    border-radius: 0.25rem;
    font-family: inherit;
    font-weight: 500;
    width: 100%;
    color: $primary;
    &::-webkit-calendar-picker-indicator {
        background-image: url('~@/assets/icons/clock_icon.svg');
    }
    &:active,
    &:hover {
        box-shadow: 0 0 1px 2px $primary;
    }

    &:focus {
        box-shadow: 0 0 1px 1px $primary;
    }

    &::placeholder {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 17px;
        color: $primary;
    }

    &:disabled {
        background: transparent;
        box-shadow: 0 0 1px 1px $primary;
        opacity: 0.7;
    }
}
</style>
