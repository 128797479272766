<template>
    <FormState
        v-slot="{ isChanged }"
        :current-state="currentState"
        :initial-state="initialState"
        @submit.prevent="confirm"
    >
        <h2 class="font-weight-medium">Create your event</h2>

        <fieldset class="gb-form__fieldset">
            <div class="cover-photo">
                <ImageUploader v-model="currentState.coverImageFile">
                    <template #temp>
                        <div class="profile-photo-placeholder">
                            <Badge label="Event cover photo" color="beige" />
                            <img src="@/assets/icons/photo_camera_icon.svg" />
                        </div>
                    </template>
                </ImageUploader>
            </div>
        </fieldset>

        <fieldset class="gb-form__fieldset">
            <label>Title</label>
            <BaseInput
                id="title"
                v-model="currentState.name"
                type="text"
                placeholder="name of the event"
                required
                text-align="center"
            />
        </fieldset>

        <fieldset class="gb-form__fieldset">
            <label>Description</label>
            <Textarea
                id="description"
                v-model="currentState.description"
                placeholder="write something about the event…"
            ></Textarea>
        </fieldset>
        <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
            <div>
                <label for="start-date">Start date</label>
                <DateInput
                    id="start-date"
                    v-model="currentState.startDate"
                    :min="new Date()"
                    name="Start date"
                    required
                />
            </div>
            <div>
                <label for="start-time">Start time</label>
                <TimeInput
                    id="start-time"
                    v-model="currentState.startTime"
                    name="Start time"
                    required
                />
            </div>
        </fieldset>
        <fieldset class="gb-form__fieldset gb-form__fieldset--col-2">
            <div>
                <label for="end-date">End date</label>
                <DateInput
                    id="end-date"
                    v-model="currentState.endDate"
                    name="End date"
                    :min="currentState.startDate ? new Date(currentState.startDate) : new Date()"
                    required
                />
            </div>
            <div>
                <label for="end-time">End time</label>
                <TimeInput
                    id="end-time"
                    v-model="currentState.endTime"
                    :min="min"
                    name="End time"
                    required
                />
            </div>
        </fieldset>
        <small
            v-if="
                currentState.startDate &&
                    currentState.startTime &&
                    currentState.endDate &&
                    currentState.endTime &&
                    !isValidDatesData
            "
            class="text--red"
            >Dates are not valid.</small
        >
        <fieldset class="gb-form__fieldset">
            <label for="place">Location</label>
            <CitySearch
                id="place"
                v-model="currentState.location"
                :required="currentState.location === null"
                placeholder="city of the event"
            />
        </fieldset>
        <!-- <fieldset class="gb-form__fieldset">
            <label for="occasion">Occasion</label>
            <Select v-model="currentState.occasion" placeholder="choose category">
                <option>Party</option>
                <option>Birthday</option>
                <option>Wedding</option>
            </Select>
        </fieldset> -->

        <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
            <label for="venue" :style="{ fontSize: '18px' }">Need a venue for the event?</label>
            <CollapsiblePanelStateless
                :is-collapsed="!currentState.isVenueNeeded"
                :style="{
                    display: 'grid',
                    justifyItems: 'center',
                    rowGap: '1rem'
                }"
            >
                <template #header>
                    <Button
                        id="venue"
                        :value="currentState.isVenueNeeded"
                        :primary="currentState.isVenueNeeded"
                        label="Yes, please"
                        size="small"
                        square
                        @click="currentState.isVenueNeeded = !currentState.isVenueNeeded"
                    />
                </template>
                <template #content>
                    <div :style="{ transformOrigin: 'top' }">
                        <p>Price range for each venue?</p>
                        <div
                            :style="{
                                display: 'grid',
                                columnGap: '1rem',
                                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
                            }"
                        >
                            <BaseInput
                                id="venue-min-price"
                                v-model.number="currentState.venueMinPrice"
                                type="number"
                                placeholder="min"
                            />
                            <BaseInput
                                id="venue-max-price"
                                v-model.number="currentState.venueMaxPrice"
                                type="number"
                                placeholder="max"
                            />
                        </div>
                    </div>
                </template>
            </CollapsiblePanelStateless>
        </fieldset>
        <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
            <label for="venue" :style="{ fontSize: '18px' }">Need an artist for the event?</label>
            <CollapsiblePanelStateless
                :is-collapsed="!currentState.isArtistNeeded"
                :style="{
                    display: 'grid',
                    justifyItems: 'center',
                    rowGap: '1rem'
                }"
            >
                <template #header>
                    <Button
                        id="artist"
                        :value="currentState.isArtistNeeded"
                        :primary="currentState.isArtistNeeded"
                        label="Yes, please"
                        size="small"
                        square
                        @click="currentState.isArtistNeeded = !currentState.isArtistNeeded"
                    />
                </template>
                <template #content>
                    <div :style="{ transformOrigin: 'top' }">
                        <p>Price range for each artist?</p>
                        <div
                            :style="{
                                display: 'grid',
                                columnGap: '1rem',
                                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
                            }"
                        >
                            <BaseInput
                                id="artist-min-price"
                                v-model.number="currentState.artistMinPrice"
                                type="number"
                                placeholder="min"
                            />
                            <BaseInput
                                id="artist-max-price"
                                v-model.number="currentState.artistMaxPrice"
                                type="number"
                                placeholder="max"
                            />
                        </div>
                    </div>
                </template>
            </CollapsiblePanelStateless>
        </fieldset>

        <fieldset
            v-if="isDevelopmentMode"
            class="gb-form__fieldset"
            :style="{ justifyContent: 'center' }"
        >
            <div>Dev:</div>
            <Button label="Auto-fill" size="small" square @click="autoFill" />
        </fieldset>
        <JumboButton
            v-show="isChanged && currentState.location !== null && isValidDatesData"
            label="Continue"
            type="submit"
        />
    </FormState>
</template>

<script>
import DateInput from '@/components/inputs/DateInput'
import TimeInput from '@/components/inputs/TimeInput'
import Textarea from '@/components/Textarea'
import CitySearch from '@/components/auto-suggest/CitySearch'
import Badge from '@/components/Badge'
import ImageUploader from '@/components/ImageUploader'
import { format, isEqual, isAfter } from 'date-fns'
import FormState from '@/views/profile/FormState'
import CollapsiblePanelStateless from '@/components/CollapsiblePanelStateless'

export default {
    name: 'EventForm',
    components: {
        DateInput,
        TimeInput,
        ImageUploader,
        Textarea,
        Badge,
        CitySearch,
        FormState,
        CollapsiblePanelStateless
    },
    emits: { step: null, input: null },
    data() {
        return {
            initialState: {},
            currentState: {},
            artistFilterOptions: null,
            success: false,
            loading: 0,
            submitted: false,
            min: undefined,
            isValidDatesData: false
        }
    },
    computed: {
        isDevelopmentMode() {
            return process.env.NODE_ENV === 'development'
        },
        isValid() {
            return (
                JSON.stringify(this.initialState) !== JSON.stringify(this.currentState) &&
                this.currentState.location !== null
            )
        }
    },
    watch: {
        currentState: {
            handler(val) {
                const { startDate, endDate, startTime } = val
                if (
                    startDate &&
                    endDate &&
                    startTime &&
                    isEqual(new Date(startDate), new Date(endDate))
                ) {
                    this.min = new Date(
                        `${this.currentState.startDate}T${this.currentState.startTime}`
                    )
                } else {
                    this.min = undefined
                }

                this.isValidDatesData = this.isValidDates()
            },
            deep: true
        }
    },
    mounted() {
        this.initialState = {
            name: '',
            description: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            occasion: '',
            location: null,
            isVenueNeeded: false,
            isArtistNeeded: false,
            artistMinPrice: null,
            artistMaxPrice: null,
            venueMinPrice: null,
            venueMaxPrice: null
        }

        this.currentState = JSON.parse(JSON.stringify(this.initialState))
    },
    methods: {
        async confirm() {
            if (this.currentState.location === null) {
                alert('Please pick a city from the dropdown list!')
            } else {
                this.$emit('input', this.currentState)
                this.$emit('step')
            }
        },
        isValidDates() {
            const { startDate, endDate, startTime, endTime } = this.currentState
            const start = new Date(startDate + 'T' + startTime)
            const end = new Date(endDate + 'T' + endTime)

            return isAfter(end, start)
        },
        autoFill() {
            const dateInterval = 30
            let start = this.$faker().date.soon(dateInterval)
            let end = new Date(
                new Date(start).setTime(new Date(start).getTime() + 2 * 60 * 60 * 1000)
            )

            this.currentState = {
                ...this.currentState,
                name: `My ${this.capitalizeFirstLetter(this.$faker().hacker.verb())} Event`,
                description: this.capitalizeFirstLetter(this.$faker().hacker.phrase()),
                startDate: format(start, 'yyyy-MM-dd'),
                endDate: format(end, 'yyyy-MM-dd'),
                startTime: this.getTimeString(start),
                endTime: this.getTimeString(end),
                location: {
                    city: 'Bergen',
                    lat: 60.3925,
                    long: 5.3233,
                    name: 'Bergen'
                },
                occasion: '',
                isArtistNeeded: true,
                isVenueNeeded: true,
                artistMinPrice: 1000,
                artistMaxPrice: 2000,
                venueMinPrice: 2000,
                venueMaxPrice: 3000
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        getTimeString(date) {
            const prependLeadingZero = (n) => (n < 10 ? `0${n}` : n)
            let hourString = prependLeadingZero(date.getHours())
            let minuteString = prependLeadingZero(date.getMinutes())
            return `${hourString}:${minuteString}`
        }
    }
}
</script>

<style lang="scss" scoped>
.zip-city {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
    & > :first-child {
        grid-column: 1 / 5;
    }
    & > :nth-child(2) {
        grid-column: 5 / 13;
    }
}
.cover-photo {
    width: 100%;
    height: 160px;
    background: #fff;
}

.profile-photo-placeholder {
    display: grid;
    place-items: center;
    place-content: center;
    row-gap: 1rem;
    height: 100%;
    width: 100%;
    label {
        display: block;
    }
}
</style>
