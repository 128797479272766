<template>
    <ValidationProvider
        v-slot="{ errors }"
        mode="eager"
        :name="name"
        :rules="{ dateregex: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/, required }"
        class="input-container"
    >
        <input
            v-bind="$attrs"
            type="date"
            :min="minString"
            :max="maxString"
            placeholder="yyyy-mm-dd"
            @input="$emit('input', $event.target.value)"
            @blur="$emit('blur', $event)"
        />
        <small v-if="errors" class="text--red">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules'
import { format } from 'date-fns'
extend('required', {
    ...required,
    message: '{_field_} is required'
})
extend('dateregex', {
    ...regex,
    message: () => 'Please follow the format yyyy-mm-dd'
})
export default {
    name: 'DateInput',
    components: {
        ValidationProvider
    },
    props: {
        min: {
            type: Date,
            default: undefined
        },
        max: {
            type: Date,
            default: undefined
        },
        name: {
            type: String,
            default: 'Date'
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'blur'],
    computed: {
        minString() {
            return this.min && this.formatDate(this.min)
        },
        maxString() {
            return this.max && this.formatDate(this.max)
        }
    },
    methods: {
        formatDate(date) {
            return format(date, 'yyyy-MM-dd')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.input-container {
    display: grid;
    justify-items: center;
    * {
        min-width: 90%;
    }
}
::-webkit-datetime-edit {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-text {
    padding: 0;
    text-align: left;
}
::-webkit-datetime-edit-month-field {
    padding: 0;
}
::-webkit-datetime-edit-day-field {
    padding: 0;
}
::-webkit-datetime-edit-year-field {
    padding: 0;
}
::-webkit-inner-spin-button {
    padding: 0;
    display: block;
}

::-webkit-calendar-picker-indicator {
    padding: 0;
    margin: 0;
    width: 14px;
    height: 14px;
    &:focus {
        outline: 0;
    }
}

input[type='date'] {
    font-family: inherit;
    background-color: white;
    padding: 1rem;
    margin: 0;
    border-radius: 0;
    outline: 0;
    border: 0;

    font-size: 1.125rem;
    line-height: 1.25rem;
    border-radius: 0.25rem;
    font-weight: 500;
    width: 100%;
    color: $primary;
    &::-webkit-calendar-picker-indicator {
        background-image: url('~@/assets/icons/calendar_icon.svg');
    }

    &:active,
    &:hover {
        box-shadow: 0 0 1px 2px $primary;
    }

    &:focus {
        box-shadow: 0 0 1px 1px $primary;
    }

    &::placeholder {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 17px;
        color: $primary;
    }

    &:disabled {
        background: transparent;
        box-shadow: 0 0 1px 1px $primary;
        opacity: 0.7;
    }
}
</style>
