<template>
    <form
        v-if="isNoneRequested || isNoneNeeded"
        class="none-requested"
        @click.prevent="handleSubmit"
    >
        <h2>You have not requested any artists or venues</h2>
        <p>Do you want to create the event?</p>
        <LoadingEllipsis v-if="isLoading">Loading</LoadingEllipsis>
        <Button label="Send request" :disabled="isLoading" primary type="submit" />
        <Button label="Go back" :disabled="isLoading" @click="$emit('previous')" />
    </form>
    <form v-else class="add-message gb-form" autocomplete="none" @submit.prevent="handleSubmit">
        <h2>Want to add a message?</h2>
        <fieldset v-if="venues.length && isVenueNeeded" class="gb-form__fieldset add-message__box">
            <div>
                <img
                    v-for="venue in venues"
                    :key="venue.id"
                    :src="venue.avatar"
                    class="avatar-xsm"
                />
            </div>

            <label for="venuePrice">Offering price for venues</label>
            <BaseInput
                id="venuePrice"
                v-model.number="pricePerVenue"
                type="number"
                placeholder="offering price for venue"
                text-align="center"
                required
            />
            <Textarea
                id="venue"
                v-model="venueMessage"
                required
                placeholder="write something to the venues"
            />
        </fieldset>
        <fieldset
            v-if="artists.length && isArtistNeeded"
            class="add-message__box gb-form__fieldset"
        >
            <div>
                <img
                    v-for="artist in artists"
                    :key="artist.id"
                    :src="artist.avatar"
                    class="avatar-xsm"
                />
            </div>
            <label for="artistPrice">Offering price for artists</label>
            <BaseInput
                id="artistPrice"
                v-model.number="pricePerArtist"
                type="number"
                placeholder="offering price for venue"
                text-align="center"
                required
            />
            <Textarea
                id="artist"
                v-model="artistMessage"
                required
                placeholder="write something to the artists"
            />
        </fieldset>
        <div class="add-message__buttons">
            <LoadingEllipsis v-if="isLoading">{{ loadingMessage }}</LoadingEllipsis>
            <Button label="Send request" primary fluid :disabled="isLoading" type="submit" />
            <Button label="Go back" fluid :disabled="isLoading" @click="$emit('previous')" />
        </div>
    </form>
</template>

<script>
import Textarea from '@/components/Textarea'
import Button from '@/components/Button'
export default {
    name: 'AddMessage',
    components: {
        Button,
        Textarea
    },
    props: {
        venues: {
            type: Array,
            required: true,
            default: () => []
        },
        artists: {
            type: Array,
            required: true,
            default: () => []
        },
        isVenueNeeded: {
            type: Boolean,
            required: true
        },
        isArtistNeeded: {
            type: Boolean,
            required: true
        },
        venueMaxPrice: {
            type: Number,
            default: 0
        },
        venueMinPrice: {
            type: Number,
            default: 0
        },
        artistMaxPrice: {
            type: Number,
            default: 0
        },
        artistMinPrice: {
            type: Number,
            default: 0
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        loadingMessage: {
            type: String,
            default: ''
        }
    },
    emits: ['previous', 'input', 'step', 'complete'],
    data() {
        return {
            venueMessage: '',
            artistMessage: '',
            pricePerArtist: (this.artistMinPrice + this.artistMaxPrice) / 2,
            pricePerVenue: (this.venueMinPrice + this.venueMaxPrice) / 2
        }
    },
    computed: {
        isNoneRequested() {
            return !this.venues.length && !this.artists.length
        },
        isNoneNeeded() {
            return !this.isVenueNeeded && !this.isArtistNeeded
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('input', {
                isNoneRequested: this.isNoneRequested,
                venueMessage: this.venueMessage,
                artistMessage: this.artistMessage,
                pricePerArtist: this.pricePerArtist,
                pricePerVenue: this.pricePerVenue
            })
            this.$emit('complete')
        }
    }
}
</script>

<style lang="scss" scoped>
.none-requested {
    padding: 1rem;
    display: grid;
    row-gap: 1rem;
}
.add-message {
    padding: 0 1rem;
    display: grid;
    row-gap: 1rem;
    &__buttons {
        padding-top: 1rem;
        justify-self: center;
        width: 260px;
        display: grid;
        row-gap: 1rem;
    }

    &__box {
        display: grid;
        row-gap: 0.5rem;
    }
}

img + img {
    margin-left: 0.5rem;
}
</style>
